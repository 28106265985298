@charset "utf-8";

// ---------
// Variables
// ---------

// Sass Variables

// Breakpoints
  $bp-sml:  480px;
  $bp-med:  768px;
  $bp-lrg: 1024px;
  $bp-xlg: 1440px;
  $bp-2xl: 2000px;
  $bp-3xl: 2800px;

// CSS Variables
:root {

// Fonts
  --ff-disp-sans: "Bandeins Strange", "Montserrat", sans-serif;
  --ff-sans: "Inter", system-ui, -apple-system, sans-serif;
  --ff-mono: "iA Writer Duospace", "SF Pro", Consolas, Menlo, monospace;

// Sizes

  // Font sizes
  --f-xsm: 0.875rem; // 14px
  --f-sml:     1rem; // 16px
  --f-med:  1.25rem; // 20px
  --f-smi: 1.875rem; // 30px
  --f-lrg:   2.5rem; // 40px
  --f-dsp:  3.75rem; // 60px
  --f-xdp:     5rem; // 80px
  --f-2xd:  6.25rem; // 100px

  // Font weights
  --w0: 'wdth' 100;
  --w1: 'wdth' 200;
  --w2: 'wdth' 350;
  --w3: 'wdth' 500;
  --w4: 'wdth' 750;
  --w5: 'wdth' 800;

  // Widths
  --u1:  0.625rem;   // 10px
  --u2:  calc(2 * var(--u1));
  --u4:  calc(4 * var(--u1));
  --u6:  calc(6 * var(--u1));
  --u8:  calc(8 * var(--u1));
  --u10: calc(10 * var(--u1));
  --u12: calc(12 * var(--u1));
  --u14: calc(14 * var(--u1));
  --u16: calc(14 * var(--u1));
  --u18: calc(14 * var(--u1));
  --u20: calc(20 * var(--u1));

    // Semantic widths
    --measure-width: 45em;

// Duration
  --transit-short: 0.1s;
  --transit-med:   0.3s;
  --transit-long:  1.0s;
  --transit-xlong: 2.0s;

// Colours
  --grey1: #333;
  --grey2: #5a5a5a;
  --grey3: #b2b2b2;
  --grey4: #d0d0d0;
  --grey5: #f6f6f6;

  --black:  #464656;
  --purple: #9078A7;
  --pink:   #CD5B75;
  --teal:   #8BCFD2;
  --peach:  #F7EADF;

// Easings
  --ease-way-out: cubic-bezier(0.000, 0.815, 1.000, 0.675);
}

// Helpers
// -------

// Mobile-first media queries
@mixin q($breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

// Desktop-first media queries
@mixin q-down($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

// If screen is landscape
@mixin landscape() {
  @media screen and (orientation: landscape) {
    @content;
  }
}

// If screen is portrait
@mixin portrait() {
  @media screen and (orientation: portrait) {
    @content;
  }
}

@mixin autogrid($w: 100px) {
  grid-template-columns: repeat(auto-fit, minmax($w, 1fr));
}

// Import partials
// ---------------

@import
  "fonts",
  "base",
  "animations",
  "typography",
  "layout",
  "decorative",
  "logonav",
  "episode",
  "episode-list"
;
