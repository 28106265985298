.episode-list {
  max-width: 1200px;
  @extend .display;
  font-size: var(--f-sml);
  @include q($bp-med){
    font-size: var(--f-med);
  }
}

.episode-row {
  @extend .sneakylink;
  @extend .anchor;
  font-variation-settings: var(--w0);
  display: grid;
  padding: var(--u1) 0;
  // title and duration
  grid-template-columns: auto var(--u10);
  grid-column-gap: var(--u1);
  @include q($bp-sml){
    // title, duration and date
    grid-template-columns: auto var(--u10) var(--u14);
    grid-column-gap: var(--u2);
  }
  @include q($bp-med){
    // index, title, duration, date
    grid-template-columns: var(--u4) auto var(--u10) var(--u20);
  }
  @include q($bp-lrg){
    grid-column-gap: var(--u4);
  }

  &.active {
    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100vw;
      right: calc(-1 * var(--u2));
      z-index: -1;
      background-color: var(--teal);
      opacity: 0.2;
    }
  }
}

a.episode-row{
  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100vw;
    left: var(--u6);
    z-index: -1;
    background-color: var(--peach);
    opacity: 0;
    transition: opacity var(--transit-short);
  }
  // only give hover effects on larger screens
  @include q($bp-med){
    .episode-name {
      transition: var(--transit-med) ease-in 0ms;
    }
    &:hover {
      color: black;
      .episode-name {
        font-variation-settings: var(--w1);
        transition: var(--transit-short) ease-in 0ms;
      }
      &::before {
        opacity: 1;
        transition: opacity var(--transit-long) var(--ease-way-out);
      }
    }
  }
}

.inverted a.episode-row{
  &::before {
    background-color: var(--black);
  }
  @include q($bp-med){
    &:hover {
      color: white;
    }
  }
}

.episode-index {
  @extend .thin;
  display: none;
  @include q($bp-med){
    display: initial;
  }
}

.episode-title {
  overflow-wrap: nowrap;
}

.episode-date {
  @extend .thin;
  text-align: right;
  display: none;
  @include q($bp-sml){
    display: initial;
  }
}

.episode-duration {
  @extend .thin;
  text-align: right;
}

