.episode {
  @extend .vp-8;
}

// --------------
// Episode titles
// --------------

.episode .episode-index {
  @extend .f-dsp;
  @extend .display;
  @extend .regular;
  line-height: 1;
}

.episode-title {
  @extend .display;
  @extend .bold;
  @extend .caps;
  @extend .bounce-once-slow;
  font-size: 8vw;
  line-height: 0.8em;
}

// ------
// Player
// ------

$player-button-height: 63px;
$player-playbutton-width: 72px;
$player-pausebutton-width: 76px;

.player {
  margin: var(--u4) 0 0;
}

.player-button {
  color: var(--teal);
  transition: 0.1s;
  height: $player-button-height;

  &:focus svg path {
    fill: currentColor;
  }
  &:hover {
    cursor: pointer;
    transform-origin: left bottom;
    transform: scaleX(1.3);
    transition: 0.1s;
  }
  &:active {
    transform: scaleX(1.3) scaleY(0.9);
  }
}

.player-time {
  @extend .display;
  position: absolute;
  top: 0;
  left: $player-playbutton-width;
}

.player-progress {
  position: absolute;
  top: $player-button-height/2 - 3;
  left: $player-playbutton-width;
  height: calc(var(--u1)/2);
  width: 100%;
  background-color: var(--teal);
  border-color: var(--teal);

  &:not([value]) {
    border: var(--black);
    background-color: var(--black);
  }
}

.player-progress[value]::-webkit-progress-bar,
.player-progress[value]::progress-bar {
  background-color: var(--purple);
}

.player-progress[value]::-webkit-progress-value
.player-progress[value]::progress-value {
  background-color: var(--teal);
}

// Background trigger

.underlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--black);
}

.off {
  opacity: 0;
  transition: var(--transit-long);
}

.on {
  opacity: 0.4;
  transition: var(--transit-long);
}

.overlay {
  position: relative;
  top: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  z-index: 1;
}

// ---------------
// Episode details
// ---------------

.episode-details {
  @extend .anchor;
  padding-top: var(--u2);
  & > span,
  & > label {
    @extend .display;
    @extend .f-med;
    padding-top: var(--u2);
    padding-bottom: var(--u2);
  }
}

// Large blocks of text

.summary,
.shownotes,
.transcript {
  opacity: 0;
  height: 0;
  overflow: hidden;
  margin: 0;
  max-width: var(--measure-width);
  transform: translateY(calc( -1 * var(--u2)));
  transition: opacity var(--transit-short), transform var(--transit-med);
}

// Hide/show behaviour

.tab {
  @extend .hidden;
}

.tab-label {
  @extend .anchor;
  display: inline-block;
  padding-left: var(--u2);
  padding-right: var(--u2);
  font-variation-settings: var(--w0);
  text-align: center;
  transition: var(--transit-short);
  &:hover {
    cursor: pointer;
    font-variation-settings: var(--w3);
    transition: var(--transit-short);
  }
  @include q($bp-med){
    min-width: calc( 20 * var(--u1) );
  }
  @include q($bp-lrg){
    width: calc( 25 * var(--u1) );
    padding-left: var(--u4);
    padding-right: var(--u4);
  }
}

.tab#tab-summary:checked ~ label[for="tab-summary"],
.tab#tab-shownotes:checked ~ label[for="tab-shownotes"], 
.tab#tab-transcript:checked ~ label[for="tab-transcript"] {
  @extend .wide2;
  transition: var(--transit-med);
  &::before,&::after {
    color: inherit;
    stroke: 1px;
    position: absolute;
    top: calc(1.5 * var(--u1));
    opacity: 0.8;
  }
  &::before {
    content: '⟨';
    left: var(--u1);
    transform: scale(3,2.5);
  }
  &::after {
    content: '⟩';
    right: var(--u1);
    transform: scale(3,2.5);
  }
}

.tab#tab-summary:checked ~ .summary,
.tab#tab-shownotes:checked ~ .shownotes, 
.tab#tab-transcript:checked ~ .transcript {
  margin: var(--u2) 0;
  opacity: 1;
  transform: translateY(0);
  height: initial;
  overflow: initial;
  transition: opacity var(--transit-short), transform var(--transit-med), height var(--transit-short);
}

