// ------
// Layout
// ------

// Layout

.flex {
  display: flex;
  align-items: center;
}

.flex-auto {
  flex: 1 1 auto;
}

.grid {
  display: grid;
}

// Mutators

.anchor {
  position: relative;
}

.screen-edge {
  padding-top: var(--u2);
  padding-left: var(--u2);
  padding-right: var(--u2);
  padding-bottom: var(--u2);
  @include q($bp-lrg){
    padding-left: var(--u4);
    padding-right: var(--u4);
  }
  @include q($bp-xlg){
    padding-left: var(--u10);
    padding-right: var(--u10);
  }
}

// Spacers


.vp-1 {
  padding-top: var(--u1);
  padding-bottom: var(--u1);
}

.vp-2 {
  padding-top: calc( 2 * var(--u1) );
  padding-bottom: calc( 2 * var(--u1) );
}

.vp-4 {
  padding-top: calc( 4 * var(--u1) );
  padding-bottom: calc( 4 * var(--u1) );
}

.vp-8 {
  padding-top: calc( 4 * var(--u1) );
  padding-bottom: calc( 4 * var(--u1) );
  @include q($bp-med){
    padding-top: calc( 8 * var(--u1) );
    padding-bottom: calc( 8 * var(--u1) );
  }
}

.vp-16 {
  padding-top: calc( 8 * var(--u1) );
  padding-bottom: calc( 8 * var(--u1) );
  @include q($bp-med){
    padding-top: calc( 12 * var(--u1) );
    padding-bottom: calc( 12 * var(--u1) );
  }
  @include q($bp-xlg){
    padding-top: calc( 16 * var(--u1) );
    padding-bottom: calc( 16 * var(--u1) );
  }
}

