// Horizontal rule

hr {
  border: none;
  width: 80vw;
  height: calc( var(--u1) / 2);
  background-color: currentColor;
}

hr:not([class]) {
  width: 100%;
  background-color: var(--peach);
  margin: var(--u4) 0;
}

hr.rule-left {
  margin-left: 0;
  margin-right: auto;
}

hr.rule-right {
  margin-left: auto;
  margin-right: 0;
}

// Text blocks

.alternate {
  div {
    margin-bottom: var(--u6);
    &:last-child {
      margin-bottom: 0;
    }
    .big-text {
      text-align: center;
    }
    @include q($bp-med) {
      display: flex;
      align-items: center;
      &:nth-child(odd) {
        flex-direction: row;
        section,
        blockquote {
          padding-right: var(--u2);
        }
        section:last-child,
        blockquote:last-child {
          padding-right: 0;
        }
        .big-text {
          text-align: left;
        }
      }
      &:nth-child(even) {
        flex-direction: row-reverse;
        section,
        blockquote {
          padding-left: var(--u2);
        }
        section:last-child,
        blockquote:last-child {
          padding-left: 0;
        }
        .big-text {
          text-align: right;
        }
      }
    }
  }
}

  .big-text {
    flex: 0 1 auto;
    word-break: break-word;
    @include q($bp-med) {
      flex: 1 1 40%;
    }
    @include q($bp-lrg) {
      font-size: 6vw;
    }
  }
  .flex-space {
    flex: 1 1 0;
  }
  .paragraphs {
    flex: 0 1 auto;
    width: 100%;
    @include q($bp-med) {
      flex: 1 0 50%;
    }
  }

// Footer

footer {
  @extend .screen-edge;
  @extend .display;
  font-size: var(--f-med);
  margin: var(--u6) 0;
}

#footer-nav {
  float: none;
  padding-top: var(--u2);
  font-variation-settings: var(--w1);
  a {
    @extend .sneakylink;
    margin-right: var(--u2);
  }
  @include q($bp-med){
    float: right;
    padding-top: 0;
    font-variation-settings: var(--w2);
    a {
      margin-left: var(--u4);
    }
  }
}