// Logo

$logo-height: 51px;
$logo-width: 71px;

.logonav {
  padding: var(--u2) 0 0;
}

.logo,
.platformlinks {
  @extend .display;
}

.logo {
  position: relative;
  width: $logo-width;
  height: $logo-height;
  margin: 0 auto;
  transform-origin: center;
}

  .logo-text {
    @extend .display;
    @extend .f-med;
    @extend .regular;
    word-spacing: -0.2em;
    text-transform: lowercase;
    font-variation-settings: var(--w0);
    text-align: center;
    transition: var(--transit-med);
    a {
      @extend .sneakylink;
    }
  }

  .logo-left {
    position: absolute;
    top: 0;
    left: 0;
  }

  .logo-right {
    position: absolute;
    top: 0;
    right: 0;
  }

  .logo-left, .logo-right {
    transition: transform var(--transit-med) ease-in-out var(--transit-short);
  }

// Platform links

.platformlinks {
  @extend .f-sml;
  width: 100%;
  max-width: $bp-lrg;
  margin: 0 auto;
  text-transform: uppercase;

  ul {
    @extend .nls;
    @include q($bp-sml){
      display: grid;
      @include autogrid;
    }
  }

  li {
    display: block;
    text-align: center;
    padding: var(--u2);
    @include q($bp-sml){
      display: inline-block;
      padding: 0;
    }
  }

  a {
    @extend .sneakylink;
    padding: var(--u1);
    font-variation-settings: var(--w0);
    transition: var(--transit-short);
    &:hover {
      font-variation-settings: var(--w2);
      transition: var(--transit-short);
    }
  }
}

.logonav {
  .platformlinks {
    display: none;
    @include q($bp-med){
      display: block;
      position: relative;
      top: calc(-7.75 * var(--u1));
      opacity: 0;
      transition: opacity var(--transit-med) ease-in-out var(--transit-short);

      ul {
        margin-top: calc((#{$logo-height} - 2*var(--f-med)));
      }

      a {
        pointer-events: none;
      }
    }
  }
}

// Nav only reveals on non-mobile interfaces

@include q($bp-med){
  .logonav:hover {

    // logo animates out
    .logo-left, .logo-right {
      transition: transform var(--transit-short) ease-in-out 0.5s;
    }
    .logo-left {
      transform: translateX(-50vw);
    }
    .logo-right {
      transform: translateX(50vw);
    }
    .logo-text {
      font-variation-settings: var(--w4);
      transition: var(--transit-short);
    }

    // nav reveals itself
    .platformlinks {
      opacity: 1;
      transition: opacity var(--transit-med) ease-in-out 0.6s;
      a {
        pointer-events: auto;
      }
    }
  }
}